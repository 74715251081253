.Card {
    color: rgb(255, 255, 255);
    background-color: #1c1c1d;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    max-width: 800px;    
}

@font-face {
    font-family: 'Fira_Mono';
    src: url('FiraMono/FiraMono-Medium.woff');
}

@font-face {
    font-family: 'Open_Sans';
    src: url('OpenSans/OpenSans-Regular.woff');
}

.embed-youtube {
    position: relative;
    padding-bottom: 56.25%; /* - 16:9 aspect ratio (most common) */
    /* padding-bottom: 62.5%; - 16:10 aspect ratio */
    /* padding-bottom: 75%; - 4:3 aspect ratio */
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.embed-youtube iframe,
.embed-youtube object,
.embed-youtube embed {
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.title {
    padding: 15px;
    font-family: Fira_Mono,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.card-image {
    /* max-height: 300px; */
    width: 83%;
    max-width: 600px;
    height: auto;
}

.section {
    padding: 30px;
    white-space: pre-wrap;
    text-align: justify;
    font-family: Open_Sans,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.link-section {
    padding: 30px;
    font-size: large;
    font-family: Open_Sans,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}


.store-button {
    max-width: 180px;
    height: auto;
    width: 50%;
    margin-bottom: 30px;
}


