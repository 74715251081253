.contact-list-element {
    padding: 15px;
    padding-bottom: 0px;
}

.horizontal-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.link-item {
    padding: 30px;
}

.border-test {
    border-radius: 90%;
}

.Card {
    color: rgb(255, 255, 255);
    background-color: #1c1c1d;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 30px;
    border-radius: 10px;
    max-width: 800px;    
}

@font-face {
    font-family: 'Fira_Mono';
    src: url('FiraMono/FiraMono-Medium.woff');
}

@font-face {
    font-family: 'Open_Sans';
    src: url('OpenSans/OpenSans-Regular.woff');
}

.title {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0px; 
    font-family: Fira_Mono,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.card-image {
    /* max-height: 300px; */
    width: 83%;
    max-width: 600px;
    height: auto;
}

.section {
    padding: 15px;
    white-space: pre-wrap;
    text-align: justify;
    font-family: Open_Sans,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.link-section {
    padding: 15px;
    font-size: large;
    font-family: Open_Sans,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.link {
    padding: 15px;
    font-size: large;
    font-family: Open_Sans,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}


