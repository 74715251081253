@font-face {
    font-family: 'Fira_Mono';
    src: url('FiraMono/FiraMono-Medium.woff');
}

.header-title {
    color: white;
    vertical-align: middle;
    font-family: Fira_Mono,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.header-link {
    text-decoration: none;
}
.header {
    padding: 0.3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #1c1c1d;
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.active {
    background-color: #1c1c1d;   
}

.navbar {
    display: flex;
    align-items: center;
}

.menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
    display: flex;
    align-items: center;
}

.nav-menu {
    background-color: #1c1c1d; 
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
    border-left-style: solid;
    border-width: 1px;
    border-color: black;
}

.nav-menu.active {
    right: 0;
    transition: 350ms;
}

.nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 8px;
    list-style: none;
    height: 70px;
    font-family: Fira_Mono,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.nav-text a {
    text-decoration: none;
    color: #ffffff;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    
}

.nav-text a:hover {
    background-color: #36373b;
}

.nav-menu-items {
    width: 100%;
}

.navbar-toggle {
    background-color: #1c1c1d; 
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}

span {
    margin-left: 16px;
}


